import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import '@/assets/base.css'
import '@/assets/style.css'

// import ElementUI from 'element-ui';
// Vue.use(ElementUI);

import 'element-ui/lib/theme-chalk/index.css';
import {
  Row,
  Col,
  Button,
  Loading,
  Carousel,
  Table,
  TableColumn,
  CarouselItem,
  Popover,
} from 'element-ui';
Vue.use(Row)
Vue.use(Button)
Vue.use(Loading)
Vue.use(Col)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Popover)

// 登录背景粒子特效
import VueParticles from 'vue-particles'
Vue.use(VueParticles)


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')