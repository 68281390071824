import axios from 'axios'
import {
  Message,
  Loading
} from 'element-ui'

let loading

// create an axios instance
const service = axios.create({
  baseURL: 'https://cscpark.oramage.com', // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 100000, // request timeout
  headers: {
    //crossDomain: true, // 跨域
    // "Content-Type": "application/x-www-form-urlencoded",
    "Content-Type": "application/json",
    // "Authorization": "Bearer " + sessionStorage.getItem("token")
  }
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    
    loading = Loading.service({
      lock: true,
      body: true,
      text: '数据加载中',
      spinner: 'el-icon-loading',
      // fullscreen:false,
      // background: 'rgba(0, 0, 0, 0.2)'
    });
    
      config.headers['Authorization'] = sessionStorage.getItem("token") || localStorage.getItem("token")
     
    if (config) {
      // console.log(config, 'config',window.location.href);
    }
    return config
  },
  error => {
    loading.close();
    // do something with request error
    // console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    // const res = response.data
    // return res
    if (response) {
      // console.log(response, 'response',window.location.href);
    }
    loading.close();
    const {
      data: {
        code,
        msg,
        data,
        status
      }
    } = response;
    if (code == 0) {
      throw new Error(msg);
      return msg;
    } else if (code == 1) {
      return data;
    } else if (code == -1) {
      return response;
    } else {
      return response
    }
  },
  error => {
    loading.close();
    // console.log('err' + error) // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
