import request from '@/utils/request.js'
import request2 from '@/utils/request2.js'

export function getOneCate(data) {
  return request({
    url: '/port/drive/index',
    method: 'post',
    data
  })
}
export function getOneCate2(data) {
  return request({
    url: '/port/drive/custom',
    method: 'post',
    data
  })
}

export function getProject(data) {
  return request({
    url: '/port/drive/project',
    method: 'post',
    data
  })
}

export function getScreenIndustryData(data) {
  return request2({
    url: '/api/getScreenIndustryData',
    method: 'get',
    data
  })
}

