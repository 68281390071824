<template>
  <div class="home position_r">
    <div
      class="textSty position_a colorfff"
      style="top: 1vw; left: 1vw; font-size: 0.85vw"
    >
      {{ nowTime }}
    </div>
    <div class="position_a colorfff" style="top: 1vw; right: 1vw; text-align: right">
      <div class="textSty">{{ date }}</div>
      <div class="textSty">{{ getWeek }}</div>
    </div>
    <div class="bigTitle position_r">
      <img class="position_a" src="../images/topBg.png" />
      <div>润城在中国</div>
    </div>

    <el-row justify="center">
      <el-col :span="6" style="height: 100%">
        <div class="leftBox">
          <div class="logoBox">
            <img src="../images/logo.png" />
          </div>

          <div class="legendBox">
            <div class="item">正在运营 重资产</div>
            <div class="item">正在运营 轻资产</div>
            <div class="item">筹备项目 重资产</div>
            <div class="item">正在运营 创新中心</div>
          </div>

          <div class="dataBox">
            <div class="dataItem">
              <div class="text">
                <div>
                  <span>{{ region[0] }}个</span>
                </div>
                <div>项目个数</div>
              </div>
              <div class="img">
                <img src="../images/project.png" />
              </div>
            </div>
            <div class="dataItem">
              <div class="text">
                <div>
                  <span>{{ region[1] }}万平方米</span>
                </div>
                <div>运营面积</div>
              </div>
              <div class="img">
                <img src="../images/area2.png" />
              </div>
            </div>
            <div class="dataItem">
              <div class="text">
                <div>
                  <span>{{ region[2] }}个</span>
                </div>
                <div>企业数量</div>
              </div>
              <div class="img">
                <img src="../images/enterprise.png" />
              </div>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="12" style="height: 100%">
        <div class="echart" ref="chartone" id="main">
          <canvas class="rain"></canvas>

          <div class="cicle3"></div>
          <!-- <div class="cicle4"></div> -->

          <img src="../assets/chineseMap.png" />

          <div class="mapTitle">
            <div class="name" @click="BunType(3)">华南区域</div>
            <div class="small"></div>
            <div class="big"></div>
          </div>

          <div class="mapTitle mapTitle2">
            <div class="name" @click="BunType(2)">华西区域</div>
            <div class="small"></div>
            <div class="big"></div>
          </div>

          <div class="mapTitle mapTitle3">
            <div class="name" @click="BunType(4)">华北区域</div>
            <div class="small"></div>
            <div class="big"></div>
          </div>

          <div class="mapTitle mapTitle4">
            <div class="name" @click="BunType(5)">华中区域</div>
            <div class="small"></div>
            <div class="big"></div>
          </div>

          <div class="mapTitle mapTitle5">
            <div class="name" @click="BunType(1)">华东区域</div>
            <div class="small"></div>
            <div class="big"></div>
          </div>
        </div>
      </el-col>
      <el-col :span="6" style="height: 100%">
        <div class="butBox">
          <div class="but1" @click="BunType(0)" :class="type == 0 ? 'but2' : ''">
            全部
          </div>
          <div class="but1" @click="BunType(4)" :class="type == 4 ? 'but2' : ''">
            华北区域
          </div>
          <div class="but1" @click="BunType(3)" :class="type == 3 ? 'but2' : ''">
            华南区域
          </div>
          <div class="but1" @click="BunType(2)" :class="type == 2 ? 'but2' : ''">
            华西区域
          </div>
          <div class="but1" @click="BunType(1)" :class="type == 1 ? 'but2' : ''">
            华东区域
          </div>
          <div class="but1" @click="BunType(5)" :class="type == 5 ? 'but2' : ''">
            华中区域
          </div>
        </div>
        <div class="mapListBoxNew">
          <div
            class="mapListBox"
            ref="scroll_box"
            @mousemove="mousemove"
            @mouseleave="mouseleave"
          >
            <div class="ListItem" v-if="type == 3 || type == 0">
              <div class="title">
                <img src="../assets/iconImg.png" />
                <div>华南区域</div>
              </div>
              <div class="list">
                <div
                  v-for="item in dataProject.three"
                  :key="item.id"
                  :style="{ '--color': valueColor[item.value - 1] }"
                >
                  {{ item.name }}
                </div>
              </div>
            </div>

            <div class="ListItem" v-if="type == 1 || type == 0">
              <div class="title">
                <img src="../assets/iconImg.png" />
                <div>华东区域</div>
              </div>
              <div class="list">
                <div
                  v-for="item in dataProject.one"
                  :key="item.id"
                  :style="{ '--color': valueColor[item.value - 1] }"
                >
                  {{ item.name }}
                </div>
              </div>
            </div>

            <div class="ListItem" v-if="type == 4 || type == 0">
              <div class="title">
                <img src="../assets/iconImg.png" />
                <div>华北区域</div>
              </div>
              <div class="list">
                <div
                  v-for="item in dataProject.four"
                  :key="item.id"
                  :style="{ '--color': valueColor[item.value - 1] }"
                >
                  {{ item.name }}
                </div>
              </div>
            </div>

            <div class="ListItem" v-if="type == 2 || type == 0">
              <div class="title">
                <img src="../assets/iconImg.png" />
                <div>华西区域</div>
              </div>
              <div class="list">
                <div
                  v-for="item in dataProject.two"
                  :key="item.id"
                  :style="{ '--color': valueColor[item.value - 1] }"
                >
                  {{ item.name }}
                </div>
              </div>
            </div>

            <div class="ListItem" v-if="type == 5 || type == 0">
              <div class="title">
                <img src="../assets/iconImg.png" />
                <div>华中区域</div>
              </div>
              <div class="list">
                <div
                  v-for="item in dataProject.five"
                  :key="item.id"
                  :style="{ '--color': valueColor[item.value - 1] }"
                >
                  {{ item.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
// @ is an alias to /src
import { getOneCate, getProject } from "@/api/user.js";

export default {
  components: {},
  watch: {
    //监听localStorage的变化
  },
  data() {
    return {
      name: "66",
      name2: "66",

      nowTime: "",
      getWeek: "",
      date: "",

      timer: null,
      scrollDomTop: 0,
      scrollDom: null,
      Datainfo: {},

      region: [],

      type: 0,
      typeName: [],

      dataProject: {},
      valueColor: ["#fffbcf", "#55e9e0", "#57baff", "#c59bff"],
    };
  },
  async mounted() {
    this.nowTimes();

    let { data } = await getOneCate();

    this.Datainfo = data.data;
    // this.initChart();
    this.rainBg();
    let demo = [0, 0, 0];

    this.Datainfo.t7.map((res) => {
      if (res.name == "项目数量") {
        demo[0] += Number(res.value);
      } else if (res.name == "运营面积") {
        demo[1] += Number(res.value);
      } else if (res.name == "入驻企业") {
        demo[2] += Number(res.value);
      }
    });

    this.region = [...demo];

    // 监听页面滚动
    this.scrollDom = this.$refs.scroll_box;
    // console.log(demo, '首次进入页面的滚动条');
    this.timer = setInterval(() => {
      this.scrollDomTop = this.scrollDom.scrollTop;
      this.scrollDom.scrollTop += 1;

      // console.log( this.scrollDom, '滚动条的定时器-首次进入页面');
      if (this.scrollDomTop == this.scrollDom.scrollTop) {
        this.scrollDom.scrollTop = 0;
      }
    }, 15);

    this.getProject(this.type);
  },
  created() {
    // window.addEventListener("storage", (e) => {
    //   if (e.key === "name") {
    //     this.name = e.newValue;
    //     this.name2 = e.oldValue;
    //     console.log(e.newValue);
    //   }
    // });
  },
  methods: {
    nowTimes() {
      this.timeFormate(new Date());
      setInterval(this.nowTimes, 1000);
      this.clear();
    },
    clear() {
      clearInterval(this.nowTimes);
      this.nowTimes = null;
    },
    //显示当前时间（年月日时分秒）
    timeFormate(timeStamp) {
      let year = new Date(timeStamp).getFullYear();
      let month =
        new Date(timeStamp).getMonth() + 1 < 10
          ? "0" + (new Date(timeStamp).getMonth() + 1)
          : new Date(timeStamp).getMonth() + 1;
      let date =
        new Date(timeStamp).getDate() < 10
          ? "0" + new Date(timeStamp).getDate()
          : new Date(timeStamp).getDate();
      let hh =
        new Date(timeStamp).getHours() < 10
          ? "0" + new Date(timeStamp).getHours()
          : new Date(timeStamp).getHours();
      let mm =
        new Date(timeStamp).getMinutes() < 10
          ? "0" + new Date(timeStamp).getMinutes()
          : new Date(timeStamp).getMinutes();
      let ss =
        new Date(timeStamp).getSeconds() < 10
          ? "0" + new Date(timeStamp).getSeconds()
          : new Date(timeStamp).getSeconds();
      let week = new Date(timeStamp).getDay();
      let weeks = ["日", "一", "二", "三", "四", "五", "六"];
      this.getWeek = "星期" + weeks[week];
      this.date = year + "年" + month + "月" + date + "日";
      this.nowTime = hh + ":" + mm + ":" + ss;
    },
    BunType(type) {
      this.type = type;
    },
    getProject(type) {
      getProject({
        type: type ? type : "",
      }).then((res) => {
        console.log(res.data.data);
        this.dataProject = res.data.data;
      });
    },
    mousemove() {
      clearInterval(this.timer);
      this.timer = null;
    },
    mouseleave() {
      this.timer = setInterval(() => {
        this.scrollDomTop = this.scrollDom.scrollTop;
        this.scrollDom.scrollTop += 1;
        if (this.scrollDomTop == this.scrollDom.scrollTop) {
          this.scrollDom.scrollTop = 0;
        }
      }, 15);
    },
    // 中间背景雨
    rainBg() {
      var c = document.querySelector(".rain");
      var ctx = c.getContext("2d"); //获取canvas上下文
      var w = (c.width = document.querySelector(".echart").clientWidth);
      var h = (c.height = document.querySelector(".echart").clientHeight);
      //设置canvas宽、高

      function random(min, max) {
        return Math.random() * (max - min) + min;
      }

      function RainDrop() {}
      //雨滴对象 这是绘制雨滴动画的关键
      RainDrop.prototype = {
        init: function () {
          this.x = random(0, w); //雨滴的位置x
          this.y = h; //雨滴的位置y
          this.color = "hsl(180, 100%, 50%)"; //雨滴颜色 长方形的填充色
          this.vy = random(4, 50); //雨滴下落速度
          this.hit = 0; //下落的最大值
          this.size = 8; //长方形宽度
        },
        draw: function () {
          if (this.y > this.hit) {
            var linearGradient = ctx.createLinearGradient(
              this.x,
              this.y,
              this.x,
              this.y + this.size * 30
            );
            // 设置起始颜色
            linearGradient.addColorStop(0, "#14789c");
            // 设置终止颜色
            linearGradient.addColorStop(1, "rgba(255, 255, 255, 0)");
            // 设置填充样式
            ctx.fillStyle = linearGradient;
            ctx.fillRect(this.x, this.y, this.size, this.size * 50); //绘制长方形，通过多次叠加长方形，形成雨滴下落效果
          }
          this.update(); //更新位置
        },
        update: function () {
          if (this.y > this.hit) {
            this.y -= this.vy; //未达到底部，增加雨滴y坐标
          } else {
            this.init();
          }
        },
      };

      function resize() {
        w = c.width = window.innerWidth;
        h = c.height = window.innerHeight;
      }

      //初始化一个雨滴

      var rs = [];
      for (var i = 0; i < 10; i++) {
        setTimeout(function () {
          var r = new RainDrop();
          r.init();
          rs.push(r);
        }, i * 300);
      }

      function anim() {
        ctx.clearRect(0, 0, w, h); //填充背景色，注意不要用clearRect，否则会清空前面的雨滴，导致不能产生叠加的效果
        for (var i = 0; i < rs.length; i++) {
          rs[i].draw(); //绘制雨滴
        }
        requestAnimationFrame(anim); //控制动画帧
      }

      window.addEventListener("resize", resize);
      //启动动画
      anim();
    },

    // initChart() {
    //   function randomData() {
    //     return Math.round(Math.random() * 500);
    //   }
    //   var mydata = [
    //     {
    //       name: "北京",
    //       value: "100",
    //       itemStyle: { normal: { areaColor: "#67c23a" } },
    //     },
    //     { name: "天津", value: randomData() },
    //     { name: "上海", value: randomData() },
    //     { name: "重庆", value: randomData() },
    //     { name: "河北", value: randomData() },
    //     { name: "河南", value: randomData() },
    //     { name: "云南", value: randomData() },
    //     { name: "辽宁", value: randomData() },
    //     { name: "黑龙江", value: randomData() },
    //     { name: "湖南", value: randomData() },
    //     { name: "安徽", value: randomData() },
    //     { name: "山东", value: randomData() },
    //     {
    //       name: "新疆",
    //       value: randomData(),
    //       itemStyle: { normal: { areaColor: "#67c23a" } },
    //     },
    //     { name: "江苏", value: randomData() },
    //     { name: "浙江", value: randomData() },
    //     { name: "江西", value: randomData() },
    //     { name: "湖北", value: randomData() },
    //     { name: "广西", value: randomData() },
    //     { name: "甘肃", value: randomData() },
    //     { name: "山西", value: randomData() },
    //     { name: "内蒙古", value: randomData() },
    //     { name: "陕西", value: randomData() },
    //     { name: "吉林", value: randomData() },
    //     { name: "福建", value: randomData() },
    //     { name: "贵州", value: randomData() },
    //     { name: "广东", value: randomData() },
    //     { name: "青海", value: randomData() },
    //     { name: "西藏", value: randomData() },
    //     { name: "四川", value: randomData() },
    //     { name: "宁夏", value: randomData() },
    //     { name: "海南", value: randomData() },
    //     { name: "台湾", value: randomData() },
    //     { name: "香港", value: randomData() },
    //     { name: "澳门", value: randomData() },
    //   ];
    //   var optionMap = {
    //     zoom: 1.2,
    //     backgroundColor: "",
    //     title: {
    //       text: "个人存款全国分布情况",
    //       subtext: "",
    //       y: "5%",
    //       x: "center",
    //     },
    //     tooltip: {
    //       trigger: "item",
    //     },
    //     grid: {
    //       top: "0",
    //       left: "0p",
    //       right: "0",
    //       bottom: "20%",
    //     },
    //     //左侧小导航图标
    //     visualMap: {
    //       show: false,
    //       x: "left",
    //       y: "center",
    //       splitList: [
    //         { start: 500, end: 600 },
    //         { start: 400, end: 500 },
    //         { start: 300, end: 400 },
    //         { start: 200, end: 300 },
    //         { start: 100, end: 200 },
    //         { start: 0, end: 100 },
    //       ],
    //       color: ["#5475f5", "#9feaa5", "#85daef", "#74e2ca", "#e6ac53", "#9fb5ea"],
    //     },
    //     //配置属性
    //     series: [
    //       {
    //         name: "数据",
    //         type: "map",
    //         mapType: "china",
    //         label: {
    //           normal: {
    //             show: true, //省份名称
    //           },
    //           emphasis: {
    //             show: false,
    //           },
    //         },
    //         data: mydata, //数据
    //       },
    //     ],
    //   };
    //   //初始化echarts实例
    //   var myChart = echarts.init(this.$refs.chartone);
    //   //使用制定的配置项和数据显示图表
    //   myChart.setOption(optionMap);
    // },
  },
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
    width: 0;
    height: 15px;
  }
.textSty {
  font-size: 0.65vw;
}
.bigTitle {
  font-size: 1.5vw;
  font-weight: bold;
  text-align: center;
  color: transparent;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(0deg, #b9d7ff 0%, #ffffff 74.0966796875%);
  -webkit-background-clip: text;
  background-clip: text;
  padding: 1vw 0;
  margin-bottom: 0.5vw;

  img {
    left: 50%;
    top: 2vh;
    transform: translate(-50%, 0);
    width: 67%;
  }
}
.echart {
  width: 100%;
  height: 90vh;
  overflow: hidden;
  // background-image: url('../assets/chineseMap.png');
  // background-repeat: no-repeat;
  // background-size: contain;
  // background-position: 50%;
  position: relative;
  img {
    width: 100%;
    height: 105%;
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 0;
  }

  .cicle3 {
    width: 40vw;
    height: 40vw;
    background: url(../images/yuan.png) no-repeat center;
    background-size: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: garden 20s linear infinite;
    opacity: 0.4;
  }

  .cicle4 {
    width: 15vw;
    height: 15vw;
    background: url(../images/yuan.png) no-repeat center;
    background-size: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: garden2 20s linear infinite;
    opacity: 0.54;
  }

  .mapTitle {
    position: absolute;
    color: #fff;
    width: 10vw;
    height: 10vw;
    bottom: 12%;
    left: 51%;
    .name {
      position: absolute;
      font-size: 0.75vw;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-weight: bold;
      z-index: 99;
    }
    .small {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border: 0.08vw solid #fff;
      border-radius: 50vw;
      animation: wave1 1.2s linear infinite;
      animation-delay: 0.5s;
    }
    .big {
      position: absolute;
      top: 50%;
      left: 50%;
      border: 0.08vw solid #fff;
      animation: wave1 1.2s linear infinite;
      transform: translate(-50%, -50%);
    }
  }

  .mapTitle2 {
    bottom: 31%;
    left: 46%;
    .small {
      animation: wave2 1.2s linear infinite;
      animation-delay: 0.5s;
    }
    .big {
      animation: wave2 1.2s linear infinite;
    }
  }

  .mapTitle3 {
    bottom: 40%;
    left: 56%;
  }

  .mapTitle4 {
    bottom: 33%;
    left: 59%;
    .small {
      animation: wave3 1.2s linear infinite;
      animation-delay: 0.5s;
    }
    .big {
      animation: wave3 1.2s linear infinite;
    }
  }
  .mapTitle5 {
    bottom: 24%;
    left: 64%;
  }
}
#map-container {
  // width: 100%;
  // height: 100%;

  // color: #fd9a9a;
}
.leftBox {
  padding-left: 7.5vw;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .logoBox {
    width: 100%;
    height: 10vh;
    margin-bottom: 10vh;
    margin-top: 5vh;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .legendBox {
    font-size: 0.6vw;
    color: #fff;
    border-radius: 1vw;
    width: 7vw;
    border: 0.15vw solid #fff;
    padding: 1vw 2vh;
    .item {
      position: relative;
      padding-left: 1vw;
      margin-bottom: 1vh;
      &::before {
        content: "";
        display: block;
        width: 0.4vw;
        height: 0.4vw;
        background-color: #fffbcf;
        position: absolute;
        left: 0;
        top: 50%;
        border-radius: 50%;
        transform: translateY(-50%);
      }
    }
    .item:nth-child(2) {
      &::before {
        background-color: #55e9e0;
      }
    }
    .item:nth-child(3) {
      &::before {
        background-color: #57baff;
      }
    }
    .item:nth-child(4) {
      &::before {
        background-color: #c59bff;
      }
    }
  }

  .dataBox {
    margin-top: 10vh;
    font-size: 0.9vw;
    color: #fff;
    font-weight: bold;
    .dataItem {
      display: flex;
      margin-bottom: 2vh;
      justify-content: flex-end;
      align-items: center;
      .img {
        margin-left: 1vw;
        border: 0.2vh solid #fff;
        border-radius: 10vw;
        width: 7vh;
        height: 7vh;
        padding: 1.5vh;
        box-sizing: border-box;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: right;
      }
    }
    span {
      color: #f5fa01;
      font-size: 1.3vw;
    }
  }
}
.butBox {
  display: flex;
  .but1 {
    // width: 3vw;
    height: 4vh;
    font-size: 0.6vw;
    color: #fff;
    border: 1px solid #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.5vw;
    padding: 0 0.5vw;
    border-radius: 1vh;
  }

  .but2 {
    background-color: #18ace7;
    color: #0f1b47;
    border: 1px solid #0f1b47;
  }
}
.mapListBoxNew {
  overflow: hidden;
  .mapListBox {
    width: 100%;
    box-sizing: border-box;
    height: 80vh;
    padding: 2vh 3vh 0 0;
    font-size: 0.8vw;
    color: #fff;
    overflow: scroll;
    overflow-x: hidden;
    margin: 2vh 0;
    .ListItem {
      margin-top: 2vh;
      .title {
        display: flex;
        font-size: 1vw;
        margin-bottom: 1vh;
        font-weight: bold;
        text-shadow: 2px 2px 0 #2191d6, -2px 2px 0 #2191d6, 2px -2px 0 #2191d6,
          -2px -2px 0 #2191d6;
        img {
          object-fit: contain;
          margin-right: 0.3vw;
          width: 1.4vw;
          height: 1.4vw;
        }
      }
      .list {
        div {
          position: relative;
          padding-left: 1vw;
          margin-bottom: 1vh;
          &::before {
            content: "";
            display: block;
            width: 0.4vw;
            height: 0.4vw;
            // background-color: #fffbcf;
            position: absolute;
            left: 0;
            top: 50%;
            border-radius: 50%;
            transform: translateY(-50%);
            background-color: var(--color);
          }
        }
      }
    }
  }
}

@keyframes wave1 {
  from {
    width: 4vw;
    height: 4vw;
    border-radius: 10vw;
  }
  to {
    width: 9vw;
    height: 9vw;
    border-radius: 10vw;
    opacity: 0;
    border: 0.05vw dashed #fff;
  }
}
@keyframes wave2 {
  from {
    width: 2vw;
    height: 2vw;
    border-radius: 10vw;
  }
  to {
    width: 7vw;
    height: 7vw;
    border-radius: 10vw;
    opacity: 0;
  }
}
@keyframes wave3 {
  from {
    width: 1vw;
    height: 1vw;
    border-radius: 10vw;
  }
  to {
    width: 5vw;
    height: 5vw;
    border-radius: 10vw;
    opacity: 0;
  }
}

@keyframes garden {
  0% {
    transform: translateX(-50%) translateY(-50%) rotateZ(0);
  }

  100% {
    transform: translateX(-50%) translateY(-50%) rotateZ(360deg);
  }
}
@keyframes garden2 {
  0% {
    transform: translateX(-50%) translateY(-50%) rotateZ(360deg);
  }

  100% {
    transform: translateX(-50%) translateY(-50%) rotateZ(0);
  }
}
</style>
